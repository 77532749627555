import Swiper from "swiper";

window.addEventListener("load", function () {
  (function () {
    "use strict";

    const breakpoint = window.matchMedia("(min-width:1024px)");

    let mySwiper;

    const breakpointChecker = function () {
      if (breakpoint.matches === true) {
        if (mySwiper !== undefined) mySwiper.destroy(true, true);
        return;
      } else if (breakpoint.matches === false) {
        return enableSwiper();
      }
    };

    const enableSwiper = function () {
      const swipers = document.body.querySelectorAll(".logo-swiper");
      swipers.forEach((swiper, i) => {
        new Swiper(".logo-swiper", {
          slidesPerView: "auto",
          spaceBetween: 10,
          freeMode: true,
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
        });
      });
    };

    breakpoint.addListener(breakpointChecker);

    breakpointChecker();
  })();
});
